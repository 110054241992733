export const onClientEntry = () => {
    window.onload = () => {
        if (window.MSInputMethodContext && document.documentMode) {
            var script = document.createElement('script');
            script.onload = () => {
                cssVars();
            };
            script.src = 'https://cdn.jsdelivr.net/npm/css-vars-ponyfill@2';
            document.getElementsByTagName('head')[0].appendChild(script);

            return () => {
                document.head.removeChild(script);
                document.head.removeChild(polyfillScript);
            };
        }
    };
};

// fix jump to anchor load
export const onRouteUpdate = route => {
        setTimeout(() => {
            let el = null;

            if (document.location.hash) {
                el = document.querySelector(`${document.location.hash}`);
            }

            if (el) {
                el.scrollIntoView();
            }
        }, 800);
}
